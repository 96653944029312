export const environment = {
  production: false,
  showDialogRegisterBusiness : true,
  release: '5223429498564',
  domain: 'cambix-qa.azurewebsites.net',
  api_url: 'https://cambix-backend-qa.azurewebsites.net/',
  domains: ['localhost','cambix-qa.azurewebsites.net'],
  version: 'v1/',
  secretKeyAES: 'YwelHhJSRGV9KiApzfw7IkyfCb4AHhoK',
  secretIv: 'gqLOHUioQ0QjhuvI',
  keyencrypt: 'k3y%C@mb1x1nnov@',
  recaptchaKey: '6LeJM2kaAAAAAN7OUYsKDG3H_xSzrGx95AcF74UX',
  OcpApimSubscriptionKey: '323dea19109d4fd9a628a9fb4b4dfbdf',
  appInsights: {
    instrumentationKey: 'c169d03f-0d91-48da-9321-3e2c46e6f037'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDO3C_VchwR29KTgSRHYitEQjYOS9rFkmI',
    authDomain: 'cambix-2235a.firebaseapp.com',
    databaseURL: 'https://cambix-2235a.firebaseio.com',
    projectId: 'cambix-2235a',
    storageBucket: 'cambix-2235a.appspot.com',
    messagingSenderId: '522275500605',
    appId: '1:522275500605:web:4708f93d94d69edd10f054',
    measurementId: 'G-K6QS7VCY15'
  },
  biometricConfig:{
    URL_BASE:'https://adocolombia-qa.ado-tech.com/BanComercioPeruQA',
    PROJECT_NAME:'BanComercioPeruQA',
    API_KEY:'db92efc69991'
  },
  urlCaheList: [
    'v2/exchange-rates/exchange-rate?'
  ],
  GA_TRACKING_ID: 'G-C3FMVKWWHM',
  clientIdGoogle:'915078110870-fmn3381p6tgcmvaavkfm6dpu7a4r6glj.apps.googleusercontent.com',
  leads: 'https://script.google.com/macros/s/AKfycbyCgGKml0zjsrNhz8CKdTgZEYqZtjcrrCWpwPunUO_o5yaXbv5rbo2ooaDS7UrQe48/exec'
};
